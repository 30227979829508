
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  onBeforeMount,
} from "vue";
import { mapState, useStore } from "vuex";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { FirebaseX } from "@ionic-native/firebase-x";
import JnDoc from "../../components/DocCard.vue";
import {
  document,
  search,
  logoLinkedin,
  logoInstagram,
  logoFacebook,
  mailOutline,
  caretBack,
  chevronBackCircleOutline,
  print,
} from "ionicons/icons";
import { addIcons } from "ionicons";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";
import { Categories } from "@/models/enums.model";
import * as eventBus from "../../services/event.bus.js";
import JnGlobalSearchParams from "../search/GlobalSearchParams.vue";
import { useRouter, useRoute } from "vue-router";
import { SearchTypes } from "@/models/enums.model";
import { useCookie } from "@vue-composable/cookie";
import { SocialSharing } from "@ionic-native/social-sharing";
import JnFacebookSharing from "../../components/sharing/FacebookSharing.vue";
import JnLinkedinSharing from "../../components/sharing/LinkedinSharing.vue";
import JnEmailSharing from "../../components/sharing/EmailSharing.vue";
import { useMeta } from "vue-meta";
/*import { Share } from '@capacitor/share';

await Share.share({
  title: 'See cool stuff',
  text: 'Really awesome thing you need to see right meow',
  url: 'http://ionicframework.com/',
  dialogTitle: 'Share with buddies',
});*/
addIcons({
  document: document,
  search: search,
  "logo-linkedin": logoLinkedin,
  "logo-instagram": logoInstagram,
  "logo-facebook": logoFacebook,
  "mail-outline": mailOutline,
  "caret-back": caretBack,
  "chevron-back-circle-outline": chevronBackCircleOutline,
  print: print,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonFabList,
} from "@ionic/vue";

export default defineComponent({
  name: "JnJuranewsDoc",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
    IonTitle,
    IonMenuButton,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonCardSubtitle,
    IonCardHeader,
    IonFab,
    IonFabButton,
    IonFabList,
    JnFacebookSharing,
    JnLinkedinSharing,
    JnEmailSharing,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);
    const timeout = ref(5000);

    const doc = ref();
    const paywall = ref(true);

    function goToHome() {
      location.href = "/main/home";
    }

    onBeforeMount(async () => {
      const id = route.params.id;
      eventBus.default.$on("paywall", function (data: boolean) {
        paywall.value = data;
      });

      const paywallCookie = useCookie("paywall");
      if (!paywallCookie.cookie.value) {
        paywall.value = true;
      } else {
        paywall.value = paywallCookie.cookie.value == "true";
      }

      // Eventuale ulteriore logica per capire se ha superato il paywall va messa qui
      /* if (store.getters.crmInfo) {
        paywall.value = false;
      }
      if (paywall.value) {
        const paywallCookie = useCookie("paywall");
        if (paywallCookie.cookie) {
          paywall.value = Boolean(paywallCookie.cookie);
        }
      }*/
      const searchData = new SearchData({
        index: process.env.VUE_APP_JURANEWS_INDEXES,
        id: String(id),
        size: 1,
        from: 0,
        withText: true,
        searchInMassime: false,
      });
      doc.value = await EsService.getDoc(searchData);
      if (doc.value.access == 1) {
        paywall.value = false;
      }
      loading.value = false;
    });

    const goToLogin = async () => {
      modalController.dismiss(undefined);
      store.commit("isJuranews", doc.value.isJuranews);
      store.commit("docId", doc.value.id);
      router.push("/login");
    };

    return { doc, goToLogin, paywall, goToHome };
  },
});
