<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>{{ $route.name }}</ion-title>
        <ion-button @click="goToHome()" color="primary" fill="clear" slot="end">
          <ion-icon icon="home" />
        </ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-card v-if="doc">
        <ion-card-header color="tertiary">
          <ion-grid>
            <ion-row>
              <ion-col class="ion-text-start">
                <ion-text color="secondary">
                  <b>{{ doc.materia }}</b>
                </ion-text>
              </ion-col>
              <ion-col class="ion-text-end">
                <ion-text color="secondary">
                  <b>{{ doc.dateFormatted }}</b>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row size="12">
              <ion-col size="12">
                <ion-text>
                  <h6>
                    <b>{{ doc.title }}</b>
                  </h6>
                </ion-text>
                <ion-card-subtitle class="abstract">
                  <i><div class="abstract" v-html="doc.abstract" /></i>
                </ion-card-subtitle>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>
        <ion-card-content class="text">
          <ion-grid>
            <ion-row v-if="!paywall">
              <ion-col size="12">
                <div class="text" v-html="doc.text" />
              </ion-col>
            </ion-row>
            <ion-row v-if="paywall">
              <ion-col size="12">
                <div class="text" v-html="doc.shosrtText1" />
              </ion-col>
            </ion-row>
            <ion-row v-if="paywall">
              <ion-col>
                <ion-card @click="goToLogin" color="secondary">
                  <ion-card-content class="ion-text-center">
                    <div>Accedi per visualizzare il documento</div>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-fab
        horizontal="end"
        vertical="bottom"
        slot="fixed"
        v-if="!paywall && doc"
      >
        <ion-fab-button color="secondary">
          <ion-icon
            md="caret-back"
            ios="chevron-back-circle-outline"
          ></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="start">
          <jn-facebook-sharing :doc="doc" />
          <jn-linkedin-sharing :doc="doc" />
          <jn-email-sharing :doc="doc" />
        </ion-fab-list>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  onBeforeMount,
} from "vue";
import { mapState, useStore } from "vuex";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { FirebaseX } from "@ionic-native/firebase-x";
import JnDoc from "../../components/DocCard.vue";
import {
  document,
  search,
  logoLinkedin,
  logoInstagram,
  logoFacebook,
  mailOutline,
  caretBack,
  chevronBackCircleOutline,
  print,
} from "ionicons/icons";
import { addIcons } from "ionicons";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";
import { Categories } from "@/models/enums.model";
import * as eventBus from "../../services/event.bus.js";
import JnGlobalSearchParams from "../search/GlobalSearchParams.vue";
import { useRouter, useRoute } from "vue-router";
import { SearchTypes } from "@/models/enums.model";
import { useCookie } from "@vue-composable/cookie";
import { SocialSharing } from "@ionic-native/social-sharing";
import JnFacebookSharing from "../../components/sharing/FacebookSharing.vue";
import JnLinkedinSharing from "../../components/sharing/LinkedinSharing.vue";
import JnEmailSharing from "../../components/sharing/EmailSharing.vue";
import { useMeta } from "vue-meta";
/*import { Share } from '@capacitor/share';

await Share.share({
  title: 'See cool stuff',
  text: 'Really awesome thing you need to see right meow',
  url: 'http://ionicframework.com/',
  dialogTitle: 'Share with buddies',
});*/
addIcons({
  document: document,
  search: search,
  "logo-linkedin": logoLinkedin,
  "logo-instagram": logoInstagram,
  "logo-facebook": logoFacebook,
  "mail-outline": mailOutline,
  "caret-back": caretBack,
  "chevron-back-circle-outline": chevronBackCircleOutline,
  print: print,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonFabList,
} from "@ionic/vue";

export default defineComponent({
  name: "JnJuranewsDoc",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
    IonTitle,
    IonMenuButton,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonCardSubtitle,
    IonCardHeader,
    IonFab,
    IonFabButton,
    IonFabList,
    JnFacebookSharing,
    JnLinkedinSharing,
    JnEmailSharing,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);
    const timeout = ref(5000);

    const doc = ref();
    const paywall = ref(true);

    function goToHome() {
      location.href = "/main/home";
    }

    onBeforeMount(async () => {
      const id = route.params.id;
      eventBus.default.$on("paywall", function (data: boolean) {
        paywall.value = data;
      });

      const paywallCookie = useCookie("paywall");
      if (!paywallCookie.cookie.value) {
        paywall.value = true;
      } else {
        paywall.value = paywallCookie.cookie.value == "true";
      }

      // Eventuale ulteriore logica per capire se ha superato il paywall va messa qui
      /* if (store.getters.crmInfo) {
        paywall.value = false;
      }
      if (paywall.value) {
        const paywallCookie = useCookie("paywall");
        if (paywallCookie.cookie) {
          paywall.value = Boolean(paywallCookie.cookie);
        }
      }*/
      const searchData = new SearchData({
        index: process.env.VUE_APP_JURANEWS_INDEXES,
        id: String(id),
        size: 1,
        from: 0,
        withText: true,
        searchInMassime: false,
      });
      doc.value = await EsService.getDoc(searchData);
      if (doc.value.access == 1) {
        paywall.value = false;
      }
      loading.value = false;
    });

    const goToLogin = async () => {
      modalController.dismiss(undefined);
      store.commit("isJuranews", doc.value.isJuranews);
      store.commit("docId", doc.value.id);
      router.push("/login");
    };

    return { doc, goToLogin, paywall, goToHome };
  },
});
</script>
